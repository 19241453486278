<template>
  <div>
    <div>
      <div class="button_top">
        <GardenSlwct
          :tab-data="tabData"
          :activeIndex="activeIndex"
          border-radius="40px"
          @tabAction="tabAction"
        >
        </GardenSlwct>
        <div>
          <el-button type="primary" icon="el-icon-plus" size="small" @click="openActivityControls('add')"
            >创建活动</el-button
          >
        </div>
      </div>
      <GlobalTable
        ref="GlobalTable"
        v-loading="loading"
        :columns="tableColumns"
        :data="dataList"
        :currentPage="pages.pageNum"
        :total="pages.total"
        :pageSize="pages.pageSize"
        @handleCurrentChange="handleCurrentChange"
      >
        <el-table-column label="活动名称" slot="activityName" align="center">
          <template slot-scope="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.activityName"
              placement="top"
            >
              <div class="nowrap-ellipsis">
                {{ row.activityName }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="参与商户" slot="companyName" align="center">
          <template slot-scope="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.companyName"
              placement="top"
            >
              <div class="nowrap-ellipsis">
                {{ row.companyName }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="奖励类型" slot="rewardType" align="center">
          <template slot-scope="{ row }">
            {{ row.finalPriceReward=='01'?'用户成交价':'' }}
            {{ row.finalPriceReward=='01' && row.cutPriceReward=='01'?'、':'' }}
            {{ row.cutPriceReward=='01'?'压价金额':'' }}
          </template>
        </el-table-column>
        <el-table-column label="添加人" slot="addPeople" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.createUserName }}-{{ row.createUserAccount }}</span>
          </template>
        </el-table-column>
        <el-table-column label="活动状态" slot="activityState" align="center">
          <template slot-scope="{ row }">
            <span>{{
              row.activityState == "00"
                ? "未开始"
                : row.activityState == "01"
                ? "进行中"
                : row.activityState == "02"
                ? "已结束"
                : "--"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="活动时间" slot="activityStateRegion" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.activityStateRegion }}</span>
          </template>
        </el-table-column>
        <el-table-column label="活动开关" slot="isOpen" align="center">
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.isOpen"
              @change="isEnableChange(row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="红包发放记录" slot="isIssued" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.isIssued" class="btn" @click="toLink(row)">查看发放记录</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column
          width="200"
          label="操作"
          slot="operation"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button size="mini" v-if="row.isIssued || row.isOpen" type="success" round @click="openActivityControls('see',row)"
              >查看活动</el-button
            >
            <el-button size="mini" v-else type="primary" round @click="openActivityControls('edit',row)"
              >编辑活动</el-button
            >
            <el-button size="mini" type="warning" round @click="openActivityControls('copy',row)"
              >复制活动</el-button
            >
          </template>
        </el-table-column>
      </GlobalTable>
    </div>
    <el-dialog
      title="安全验证"
      :visible.sync="commandShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="commandClosed"
    >
      <div v-if="tipExplain" style="margin-bottom: 20px;">
        <span style="color: #ff8695;font-size: 14px;">{{ tipExplain }}</span>
      </div>
      <div class="tip-centent">
        <span>{{ commandTipText }}</span>
      </div>
      <div class="command">
        <div style="width: max-content">谷歌验证码：</div>
        <el-input
          v-model.trim="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandShow = false">取消</el-button>
        <el-button
          type="primary"
          @click.native="commandSure"
          :loading="commandBtnLoading"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import GardenSlwct from "@/components/common/gardenSlwct.vue";
import moment from "moment";
export default {
  components: { GardenSlwct },
  data() {
    return {
      activeIndex: 0,
      activityState: '-1',
      tabData: [
        {
          text: "全部",
          value: "-1",
          num: 0,
          key: 'allNum'
        },
        {
          text: "未开始",
          value: "00",
          num: 0,
          key: 'unStartNum'
        },
        {
          text: "进行中",
          value: "01",
          num: 0,
          key: 'startNum'
        },
        {
          text: "已结束",
          value: "02",
          num: 0,
          key: 'endNum'
        },
      ],
      loading: false,
      dataList: [],
      tableColumns: [
        { slotName: "activityName" },
        { slotName: "companyName" },
        { slotName: "rewardType" },
        { slotName: "addPeople" },
        { label: "添加时间", prop: "createTime" },
        { slotName: "activityState" },
        { slotName: "activityStateRegion" },
        { label: "预计发放总额(元)", prop: "expectedTotalAmount" },
        { label: "红包发放上限(元)", prop: "distributionLimit" },
        { label: "当前实发总额(元)", prop: "currentActualAmount" },
        { label: "当前剩余红包总额(元)", prop: "currentRemainAmount" },
        { label: "当前红包账户余额(元)", prop: "redEnvelopeActivityBalance" },
        { slotName: "isOpen" },
        { slotName: "isIssued" },
        { slotName: "operation" },
      ],
      pages: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      commandShow: false,
      command: '',
      commandBtnLoading: false,
      tipExplain: "",
      commandTipText: "",
      commandType: "",
      currentRow: {}
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      this.handleCurrentChange(1);
    },
  },
  methods: {
    tabAction(val) {
      this.activeIndex = val;
      this.activityState = this.tabData[val].value;
      this.handleCurrentChange(1)
    },
    handleCurrentChange(val) {
      if (val) {
        this.pages.pageNum = val;
      }
      const vo = {
        activityState: this.activityState,
        companyId: this.SeachParams.companyId,
        activityId: this.SeachParams.activityId,
        isOpend: this.SeachParams.isOpend,
        activityStartTime: this.SeachParams.activityStartTime,
        activityEndTime: this.SeachParams.activityEndTime,
        createdStartTime: this.SeachParams.createdStartTime,
        createdEndTime: this.SeachParams.createdEndTime,
        pageNum: this.pages.pageNum,
        pageSize: this.pages.pageSize,
      };
      this.loading = true
      _api.getRedEnvelopeActivityList(vo).then(res => {
        if(res.code === 1){
          this.tabData.forEach(item => {
            item.num = res.data[item.key] || 0
          })
          this.dataList = res.data.ipage.records;
          this.pages.pageNum = res.data.ipage.current
          this.pages.total = res.data.ipage.total
        }
        this.loading = false
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
    isEnableChange(row) {
      this.currentRow = row;
      this.commandTipText = row.isOpen?'是否确认开启活动？':'是否确认关闭活动？';
      this.commandType = "isOpen";
      this.commandShow = true;
    },
    commandSure() {
      if(!this.command){
        return this.$message.error('请输入谷歌验证码！');
      }
      this.commandBtnLoading = true
      if(this.commandType === "isOpen"){
        let param = {
          command: this.command,
          id: this.currentRow.id,
          isOpen: this.currentRow.isOpen,
        }
        _api.closeRedEnvelopeActivity(param).then(res => {
          if(res.code === 1){
            this.$message.success(res.msg || '操作成功！');
            this.commandShow = false
          }
        }).finally(() => {
          this.commandBtnLoading = false
        })
      }
    },
    commandClosed() {
      this.command = ''
      this.handleCurrentChange()
    },
    // 查看发放记录跳转页面
    toLink(row) {
      let routeData = this.$router.resolve({
        path: "/platformOperation/redbageRelease",
        query: { companyId: row.companyId, redActivityId: row.id },
      });
      window.open(routeData.href, "_blank");
    },
    // 创建/编辑/复制活动
    openActivityControls(type,row) {
      // if(type === 'edit'){
      //   if(row.isIssued){
      //     return this.$message.error('已有红包发放记录，无法编辑')
      //   }
      //   if(row.isOpen){
      //     return this.$message.error('请先关闭活动开关')
      //   }
      // }
      this.$router.push({ path: "/platformOperation/activityControls", query: { type,id:row?row.id:'' } });
    },
  },
};
</script>

<style lang="scss" scoped>
.button_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.nowrap-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.btn {
  text-decoration: underline;
  cursor: pointer;
  color: #0981ff;
}
.tip-centent {
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
}
.command {
  display: flex;
  align-items: center;
  /deep/.el-input {
    width: 300px;
  }
}
</style>
